<template>
  <div class="standard-page">
    <!-- The header buttons -->
    <div class="d-flex justify-space-between mb-4">
      <!-- The link back to the index -->
      <v-btn
        text
        color="primary"
        @click="$router.replace({ name: 'SocialSentimentsHistory' })"
      >
        <v-icon left> arrow_back </v-icon>

        Back
      </v-btn>

      <!-- The download button -->
      <v-btn
        depressed
        color="primary"
        :disabled="isMakingDownloadRequest"
        :loading="isMakingDownloadRequest"
        @click="triggerDownload"
      >
        <v-icon left>
          file_download
        </v-icon>

        Download PDF
      </v-btn>
    </div>

    <!-- If there's some data -->
    <v-card
      v-if="response"
      class="mt-6"
      flat
    >
      <v-card-text class="text--text">
        <profile-chip
          :data="response.influencer_preview"
          :platform="response.platform"
        />

        <!-- Show the post here if brand awareness is present -->
        <div
          v-if="response.for === 'post'"
          class="mt-6"
        >
          <post :data="response" />

          <v-divider class="my-6" />
        </div>

        <div class="text-h6 font-weight-bold mt-6">
          Comments Analysis
        </div>

        <p>
          We've analyzed the comments and here's what we found!
        </p>

        <v-row class="mt-6">
          <v-col cols="12" md="6" v-if="response.distribution">
            <distribution-chart :data="response.distribution" />

            <div class="text-button font-weight-bold text-center">
              Audience Sentiment
            </div>
            <p class="text-center text-caption">
              We've grouped them into positive, negative, and neutral sentiments.
            </p>
          </v-col>

          <v-col cols="12" md="6" v-if="response.similarity_distribution">
            <similarity-chart :data="response.similarity_distribution" />

            <div class="text-button font-weight-bold text-center">
              Brand Awareness
            </div>
            <p class="text-center text-caption">
              We've tried to analyze how many of the comments are talking about the brand.
            </p>
          </v-col>

          <v-col cols="12" md="6" v-if="!response.similarity_distribution && response.emotion_distribution">
            <emotions-chart :emotions="response.emotion_distribution" :count="6" />

            <div class="text-button font-weight-bold text-center">
              Emotions Distribution
            </div>
            <p class="text-center text-caption">
              We've analyzed the comments and grouped them into different emotions.
            </p>
          </v-col>
        </v-row>

        <v-divider class="my-6" />

        <v-row>
          <v-col cols="12" md="6" v-if="response.emotion_distribution && response.similarity_distribution">
            <emotions-chart :emotions="response.emotion_distribution" />

            <div class="text-button font-weight-bold text-center">
              Emotions Distribution
            </div>
            <p class="text-center text-caption">
              We've analyzed the comments and grouped them into different emotions.
            </p>
          </v-col>

          <v-col cols="12" :md="response.similarity_distribution ? 6 : 12" v-if="response.word_distribution">
            <word-cloud :words="response.word_distribution" />

            <div class="text-button font-weight-bold text-center">
              Word Cloud
            </div>
            <p class="text-center text-caption">
              We've grouped most used words in the comments into a visual cloud!
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"

// Import children components
const Post = () => import(/* webpackChunkName: "post" */ "@/components/social-sentiments/Post")
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')

const WordCloud = () => import(/* webpackChunkName: "word-cloud" */ "@/components/social-sentiments/WordCloud")
const EmotionsChart = () => import(/* webpackChunkName: "emotions-chart" */ "@/components/social-sentiments/EmotionsChart")
const SimilarityChart = () => import(/* webpackChunkName: "similarity-chart" */ "@/components/social-sentiments/SimilarityChart")
const DistributionChart = () => import(/* webpackChunkName: "distribution-chart" */ "@/components/social-sentiments/DistributionChart")

// Subscription ID for messageEvents
const subscriptionId = Symbol("SocialSentiments/Report")

// Export the SFC
export default {
  // Name of the component
  name: "SocialSentimentsReport",

  // Register children components
  components: {
    Post,
    ProfileChip,

    WordCloud,
    EmotionsChart,
    SimilarityChart,
    DistributionChart
  },

  // Define local data variables
  data: () => ({
    // The loading state
    isLoading: false,
    // The report data
    response: null,

    // Whether the download request is being made
    triggerId: null,
    isMakingDownloadRequest: false,
    downloadLoaderId: null
  }),

  // Define local method functions
  methods: {
    /**
     * Trigger the download of the report
     *
     * @returns {void}
     */
    triggerDownload() {
      // If the request is already being made
      if (this.isMakingDownloadRequest) return

      // Otherwise
      this.triggerId = String(Date.now())
      this.isMakingDownloadRequest = true

      // Dispatch the action
      this.$store.dispatch("socialSentiments/downloadItem", {
        modelId: this.response.id,
        triggerId: this.triggerId
      })
    },

    /**
     * Handle the message event
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the clientId does not match
      if (getClientID() !== event.localData.clientId) {
        // Stop further execution
        return
      }

      // Check if the triggerId does not match
      if (this.triggerId !== event.localData.triggerId) {
        // Stop further execution
        return
      }

      // Switch through the cases
      switch (event.key) {
        case "generate-social-sentiment-pdf-started":
          // Show a global loader
          this.downloadLoaderId = Symbol()
          this.$store.dispatch("loaders/add", this.downloadLoaderId)

          break

        case "generate-social-sentiment-pdf-completed":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaderId)
          this.isMakingDownloadRequest = false

          break

        case "generate-social-sentiment-pdf-failed":
          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "An error occurred, please contact us." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaderId)
          this.isMakingDownloadRequest = false

          break
      }
    }
  },

  /**
   * As soon as the data is ready
   *
   * @returns {void}
   */
  async created() {
    // Show the loaders
    const loaderId = Symbol("SocialSentimentsReport")
    this.$store.dispatch("loaders/add", loaderId)
    this.isLoading = true

    // Fetch the report data
    try {
      // Try making the request
      const response = await axios(`/api/social-sentiments/${this.$route.params.uuid}`)

      // Set the report data
      this.response = response.data

      // Register a subscriber for messageEvents
      messageEvents.register({
        id: subscriptionId,
        module: "social-sentiments",
        type: "all",
        key: "all",
        validator: (event) => event.module === "social-sentiments" && event.localData.modelId === this.response.id,
        callback: this.handleMessageEvent
      })
    } catch (error) {
      // log using the helper function
      logger({ type: "SocialSentiments/created Fetch Report", error })

      this.$store.dispatch("toasts/add", { text: "An error occurred!" })
    } finally {
      // Hide the loaders
      this.isLoading = false
      this.$store.dispatch("loaders/remove", loaderId)
    }
  },

  /**
   * Before this component is about to be removed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the event listener
    messageEvents.deregister(subscriptionId)
  }
}
</script>
